.bg-custom-gradient {
  background: linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(153, 153, 153, 0.16)
  );
}

.rsis-image{
  border-top-right-radius: 20px!important;
  object-fit: "cover"!important;
}

.rsis-container {
  border-top-right-radius: 20px!important;
  object-fit: "cover"!important;
}